@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .values-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        padding-top: 5rem;
        padding-bottom: 5rem;
        min-height: 600px;
        background-color: rgb(243, 243, 243);

        .values-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
    
            .values-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .values-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 3rem;
                text-align: start;
                text-wrap: wrap;
                letter-spacing: -0.2rem;
                margin: 0;
                width: 100%;
    
                .values-title-color {
                    color: $accent-color;
                }
            }
    
            .values-textbox {
                text-align: start;
                font-family: $font-family;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 1.2rem;
                width: 100%;
                margin: 0;
                margin-bottom: 2.5rem;
            }

            .values-icons-general-container{
                display: flex;
                flex-direction: column;
                width: 75%;

                .values-icons-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 1.5rem;
        
                    .value-single-icon-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 25%;
        
                        .value-icon {
                            color: $accent-color;
                            width: 4rem;
                            height: 4rem;
                        }
        
                        .value-icon-text {
                            color: rgb(36, 36, 36);
                            font-family: $font-family;
                            font-weight: 400;
                            font-size: 1rem;
                        }
                    }
                }
            }
    
            
        }
    
        .values-img-container {
            display: none;
            width: 100%;
            height: 40%;
    
            .values-img {
                object-fit: cover;
                width: 100%;
    
            }
        }
    
    }
}

@media (min-width: 769px) {
    .values-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;

        .values-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            margin-right: 3rem;

            .values-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .values-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                margin-bottom: 1rem;
                width: 100%;
    
                .values-title-color {
                    color: $accent-color;
                }
            }
    
            .values-textbox {
                align-self: center;
                text-align: start;
                font-family: $font-family;
                font-size: 1rem;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 2rem;
                width: 100%;
                margin: 0;
                margin-bottom: 2.5rem;
            }

            .values-icons-general-container{
                display: flex;
                justify-content: space-between;
                width: 100%;

                .values-icons-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 40%;
                    margin-bottom: 1.5rem;
        
                    .value-single-icon-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 25%;
        
                        .value-icon {
                            color: $accent-color;
                            width: 3.5rem;
                            height: 3.5rem;
                        }
        
                        .value-icon-text {
                            color: rgb(36, 36, 36);
                            font-family: $font-family;
                            font-weight: 400;
                            font-size: 1rem;
                        }
                    }
                }
            }
    
            
        }
    
        .values-img-container {
            display: flex;
            width: auto;
            height: 25rem;
    
            .values-img {
                object-fit: cover;
                width: auto;
                height: 100%;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                border-radius: 5px;
            }
        }
    
    }
}

@media (min-width: 1200px) {
    .values-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;
        background-image: none;
        background-size: cover;
        background-color: rgb(243, 243, 243);
    
        .values-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;
            margin-right: 10rem;

            .values-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .values-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                margin-bottom: 1rem;
                width: 100%;
    
                .values-title-color {
                    color: $accent-color;
                }
            }
    
            .values-textbox {
                align-self: center;
                text-align: start;
                font-family: $font-family;
                font-size: 1.5rem;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 2.5rem;
                width: 100%;
                margin: 0;
                margin-bottom: 2.5rem;
            }

            .values-icons-general-container{
                display: flex;
                justify-content: space-between;
                width: 100%;

                .values-icons-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 40%;
                    margin-bottom: 1.5rem;
        
                    .value-single-icon-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 25%;
        
                        .value-icon {
                            color: $accent-color;
                            width: 5rem;
                            height: 5rem;
                        }
        
                        .value-icon-text {
                            color: rgb(36, 36, 36);
                            font-family: $font-family;
                            font-weight: 600;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
    
            
        }
    
        .values-img-container {
            display: flex;
            width: auto;
            height: 30rem;
    
            .values-img {
                object-fit: cover;
                width: auto;
                height: 100%;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                border-radius: 5px;
            }
        }
    
    }
}
