@import '../../resources/variables/variables.scss';


@media (max-width: 768px) {
    .courses-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        background-color: rgb(243, 243, 243);
        padding-top: 5rem;
        padding-bottom: 5rem;
        z-index: 98;
    
        .courses-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 80%;
            margin: 0;
            margin-bottom: 3rem;
        
            .courses-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                margin-left: -0.4rem;
            }
        
            .courses-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 2.8rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                margin-left: -0.4rem;
        
                .courses-title-color{
                    color: $accent-color;
                }
            }
        
           
        }
    
        .courses-single-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            margin-bottom: 4rem;
            width: 80%;
    
            .courses-single-title{
                text-align: start;
                width: 100%;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 500;
                font-size: 1.5rem;
                letter-spacing: -0.1rem;
                margin: 0;
                margin-left: 0rem;
            }
    
            .courses-single-iframe{
                width: 100%;
                height: 15rem;
                margin: 0;
                padding: 0;
                border: none;
                
            }
        }
    }
}

@media (min-width: 769px) {
    .courses-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        background-color: rgb(243, 243, 243);
        z-index: 98;
        padding-bottom: 5rem;
    
        .courses-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 80%;
            margin: 0;
            margin-bottom: 3rem;
        
            .courses-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                margin-left: -0.4rem;
            }
        
            .courses-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                margin-left: -0.4rem;
        
                .courses-title-color{
                    color: $accent-color;
                }
            }
        
           
        }
    
        .courses-single-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            margin-bottom: 5rem;
            width: 80%;
            height: 45rem;
    
            .courses-single-title{
                text-align: start;
                width: 100%;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: -0.1rem;
                margin: 0;
                margin-left: 0rem;
            }
    
            .courses-single-iframe{
                width: 100%;
                height: 90%;
                margin: 0;
                padding: 0;
                border: none;
                
            }
        }
    }
}

@media (min-width: 1200px) {
    .courses-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        background-color: rgb(243, 243, 243);
        z-index: 98;
        padding-top: 5rem;
        padding-bottom: 5rem;
    
        .courses-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 80%;
            margin: 0;
            margin-bottom: 3rem;
        
            .courses-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                margin-left: -0.4rem;
            }
        
            .courses-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                margin-left: -0.4rem;
        
                .courses-title-color{
                    color: $accent-color;
                }
            }
        
           
        }
    
        .courses-single-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            margin-bottom: 5rem;
            width: 80%;
            height: 45rem;
    
            .courses-single-title{
                text-align: start;
                width: 100%;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: -0.1rem;
                margin: 0;
                margin-left: 0rem;
            }
    
            .courses-single-iframe{
                cursor: pointer;
                object-fit: cover;
                width: 100%;
                height: 90%;
                margin: 0;
                padding: 0;
                border: none;
                
            }
        }
    }
}

