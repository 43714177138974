@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
  .home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding-top: 0vh;
    overflow: hidden;
    /* Hide overflow to prevent video from overflowing */

    #video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    #video-background-desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    .home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      /* Ensure the content stays on top of the video */
      background-color: transparent;

      .home-title {
        text-align: start;
        font-size: 4.5rem;
        font-weight: 700;
        font-family: $font-family;
        letter-spacing: -0.3rem;
        color: $main-color;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        margin: 0;

        .home-title-color {
          color: $accent-color;
        }
      }

      .home-sub-title {
        align-self: flex-start;
        text-align: start;
        color: $main-color;
        font-size: 1.5rem;
        width: 100%;
        font-family: $font-family;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        margin: 0;
        font-weight: 400;

      }
      
      .home-buttons-container{
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 12rem;

        .home-button {
          cursor: pointer;
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $accent-color;
          color: white;
          margin-top: 1.5rem;
  
          font-size: 1.2rem;
          font-family: $font-family;
          font-weight: 600;
          border-radius: 5px;
          border: none;
          box-shadow: 0px 0px 10px #313131cc;
          border: 1px solid $accent-color;
  
          height: 4rem;
          width: 15rem;
  
          transition: 0.3s;
  
          &:hover {
            background-color: white;
            color: $accent-color;
            opacity: 90%;
            border: 1px solid $accent-color;
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  
          }
  
          &:active {
            background-color: $accent-color;
            color: white;
            opacity: 90%;
          }
  
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    padding-top: 0vh;
    overflow: hidden;
    /* Hide overflow to prevent video from overflowing */

    #video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    #video-background-desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    .home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      /* Ensure the content stays on top of the video */
      background-color: transparent;
      width: 70%;

      .home-title {
        text-align: start;
        align-self: self-start;
        font-size: 5.5rem;
        font-weight: 700;
        font-family: $font-family;
        letter-spacing: -0.3rem;
        color: $main-color;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        margin: 0;

        .home-title-color {
          color: $accent-color;
        }
      }

      .home-sub-title {
        align-self: flex-start;
        text-align: start;
        margin: 0;
        margin-left: 0.2rem;
        color: $main-color;
        font-size: 1.5rem;
        width: 100%;
        font-family: $font-family;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        font-weight: 400;
      }

      .home-buttons-container{
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: space-between;
        width: 38rem;
        height: 4rem;

        .home-button {
          cursor: pointer;
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $accent-color;
          color: white;
          margin-top: 3rem;
  
          font-size: 1.2rem;
          font-family: $font-family;
          font-weight: 600;
          border-radius: 5px;
          border: none;
          box-shadow: 0px 0px 10px #313131cc;
          border: 1px solid $accent-color;
  
          height: 4rem;
          width: 18rem;
  
          transition: 0.3s;
  
          &:hover {
            background-color: white;
            color: $accent-color;
            opacity: 90%;
            border: 1px solid $accent-color;
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  
          }
  
          &:active {
            background-color: $accent-color;
            color: white;
            opacity: 90%;
          }
  
        }
      }

      


    }
  }
}

@media (min-width: 1200px) {
  .home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    padding-top: 0vh;
    overflow: hidden;
    /* Hide overflow to prevent video from overflowing */

    #video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    #video-background-desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    .home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      /* Ensure the content stays on top of the video */
      background-color: transparent;
      width: 70%;

      .home-title {
        text-align: start;
        align-self: self-start;
        font-size: 5.5rem;
        font-weight: 700;
        font-family: $font-family;
        letter-spacing: -0.3rem;
        color: $main-color;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        margin: 0;

        .home-title-color {
          color: $accent-color;
        }
      }

      .home-sub-title {
        align-self: flex-start;
        text-align: start;
        margin: 0;
        margin-left: 0.2rem;
        color: $main-color;
        font-size: 1.5rem;
        width: 100%;
        font-family: $font-family;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        font-weight: 400;
      }

      .home-buttons-container{
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: space-between;
        width: 38rem;
        height: 4rem;

        .home-button {
          cursor: pointer;
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $accent-color;
          color: white;
          margin-top: 3rem;
  
          font-size: 1.2rem;
          font-family: $font-family;
          font-weight: 600;
          border-radius: 5px;
          border: none;
          box-shadow: 0px 0px 10px #313131cc;
          border: 1px solid $accent-color;
  
          height: 4rem;
          width: 18rem;
  
          transition: 0.3s;
  
          &:hover {
            background-color: white;
            color: $accent-color;
            opacity: 90%;
            border: 1px solid $accent-color;
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  
          }
  
          &:active {
            background-color: $accent-color;
            color: white;
            opacity: 90%;
          }
  
        }
      }

    }
  }
}

@media (min-width: 1600px) {
  .home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    padding-top: 0vh;
    overflow: hidden;
    /* Hide overflow to prevent video from overflowing */

    #video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    #video-background-desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      /* Place the video on top of other content */
    }

    .home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      /* Ensure the content stays on top of the video */
      background-color: transparent;
      width: 70%;

      .home-title {
        text-align: start;
        align-self: self-start;
        font-size: 6.5rem;
        font-weight: 700;
        font-family: $font-family;
        letter-spacing: -0.3rem;
        color: $main-color;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
        margin: 0;

        .home-title-color {
          color: $accent-color;
        }
      }

      .home-sub-title {
        align-self: flex-start;
        text-align: start;
        margin: 0;
        margin-left: 0.2rem;
        color: $main-color;
        font-size: 1.5rem;
        font-weight: 400;
        width: 100%;
        font-family: $font-family;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
      }

      .home-buttons-container{
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: space-between;
        width: 38rem;
        height: 4rem;

        .home-button {
          cursor: pointer;
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $accent-color;
          color: white;
          margin-top: 3rem;
  
          font-size: 1.2rem;
          font-family: $font-family;
          font-weight: 600;
          border-radius: 5px;
          border: none;
          box-shadow: 0px 0px 10px #313131cc;
          border: 1px solid $accent-color;
  
          height: 4rem;
          width: 18rem;
  
          transition: 0.3s;
  
          &:hover {
            background-color: white;
            color: $accent-color;
            opacity: 90%;
            border: 1px solid $accent-color;
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
  
          }
  
          &:active {
            background-color: $accent-color;
            color: white;
            opacity: 90%;
          }
  
        }
      }

      
    }
  }
}