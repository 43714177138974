@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .footer-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150vh;
        min-height: 1000px;
        padding-top: 5rem;
        padding-bottom: 10rem;
        width: 100%;
        border-top: 1px solid $accent-color;
    
        .footer-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
    
            .footer-logo {
                object-fit: cover;
                height: 5rem;
                width: auto;
            }
    
            .footer-textbox {
                text-align: start;
                line-height: 1.2rem;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 400;
                font-size: 1rem;
                margin: 0;
                margin-bottom: 1.5rem;
            }
    
            .footer-socials-title {
                color: rgb(36, 36, 36);
                text-decoration: underline;
                font-family: $font-family;
                font-weight: 400;
                margin: 0;
                margin-bottom: 1rem;
    
            }
    
            .footer-socials-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 90%;
                margin-bottom: 5rem;
    
                .contact-single-icon {
                    color: rgb(36, 36, 36);
                    font-size: 3rem;
                }
            }
        }
    
        .footer-sitemap-container {
            display: flex;
            flex-direction: column;
            width: 80%;
    
            .footer-sitemap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                height: 12rem;
                margin-bottom: 1.5rem;
    
                .footer-sitemap-title {
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-align: start;
                    margin: 0;
                }
    
                .footer-sitemap-item {
                    color: rgb(36, 36, 36);
                    text-decoration: underline;
                    font-family: $font-family;
                    font-size: 1.2rem;
                    font-weight: 400;
                    text-align: start;
                    margin: 0;
                }
            }
    
            .footer-iframe-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-size: 1.5rem;
                font-weight: 500;
                text-align: start;
                margin: 0;
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
    
            .footer-iframe {
                border: none;
                height: 20rem;
                width: 100%;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */

            }
        }

        .footer-bottom-container {
            position: absolute;
            display: flex;
            align-self: flex-end;
            align-items: center;
            justify-content: center;
            width: 90%;
            bottom: 0;
            padding-left: 5%;
            padding-right: 5%;
            height: 5%;
            background-color: #141414;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.7rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-name-img {
                object-fit: cover;
                height: 1rem;
                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: fit-content;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .footer-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        height: 60vh;
        min-height: 600px;
        padding-top: 5rem;
        padding-bottom: 5rem;
        width: 100%;
        border-top: 1px solid $accent-color;
    
        .footer-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 40%;
            margin-right: 10%;
    
            .footer-logo {
                object-fit: cover;
                height: 8rem;
                width: auto;
            }
    
            .footer-textbox {
                text-align: start;
                line-height: 1.6rem;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 400;
                font-size: 1rem;
                margin: 0;
                margin-bottom: 1.5rem;
                width: 80%;
            }
    
            .footer-socials-title {
                color: rgb(36, 36, 36);
                text-decoration: underline;
                font-family: $font-family;
                font-weight: 600;
                margin: 0;
                margin-top: 2rem;
                margin-bottom: 2rem;
    
            }
    
            .footer-socials-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 80%;
                margin-bottom: 5rem;
    
                .contact-single-icon {
                    cursor: pointer;
                    color: rgb(36, 36, 36);
                    font-size: 3rem;
                }
            }
        }
    
        .footer-sitemap-container {
            display: flex;
            flex-direction: column;
            width: 30%;
    
            .footer-sitemap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                height: 15rem;
                margin-bottom: 1.5rem;
    
                .footer-sitemap-title {
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-align: start;
                    margin: 0;
                }
    
                .footer-sitemap-item {
                    cursor: pointer;
                    color: rgb(36, 36, 36);
                    text-decoration: underline;
                    font-family: $font-family;
                    font-size: 1.2rem;
                    font-weight: 400;
                    text-align: start;
                    margin: 0;
                }
            }
    
            .footer-iframe-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-size: 1.5rem;
                font-weight: 500;
                text-align: start;
                margin: 0;
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
    
            .footer-iframe {
                border: none;
                height: 15rem;
                width: 100%;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */            }
        }

        .footer-bottom-container {
            position: absolute;
            display: flex;
            align-self: flex-end;
            align-items: center;
            justify-content: flex-start;
            width: 80%;
            bottom: 0;
            padding-left: 10%;
            padding-right: 10%;
            height: 5%;
            background-color: #141414;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.9rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-name-img {
                object-fit: cover;
                height: 1rem;
                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: fit-content;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .footer-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        height: 60vh;
        min-height: 600px;
        padding-top: 5rem;
        padding-bottom: 5rem;
        width: 100%;
        border-top: 1px solid $accent-color;
        background-color: #ffffff;

    
        .footer-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 40%;
            margin-right: 10%;
    
            .footer-logo {
                object-fit: cover;
                height: 10rem;
                width: auto;
            }
    
            .footer-textbox {
                text-align: start;
                line-height: 1.6rem;
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 400;
                font-size: 1.2rem;
                margin: 0;
                margin-bottom: 1.5rem;
                width: 80%;
            }
    
            .footer-socials-title {
                color: rgb(36, 36, 36);
                text-decoration: underline;
                font-family: $font-family;
                font-weight: 300;
                margin: 0;
                margin-top: 2rem;
                margin-bottom: 2rem;
    
            }
    
            .footer-socials-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 40%;
                margin-bottom: 5rem;
    
                .contact-single-icon {
                    cursor: pointer;
                    color: rgb(36, 36, 36);
                    font-size: 3rem;
                }
            }
        }
    
        .footer-sitemap-container {
            display: flex;
            flex-direction: column;
            width: 30%;
    
            .footer-sitemap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                height: 15rem;
                margin-bottom: 1.5rem;
    
                .footer-sitemap-title {
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-align: start;
                    margin: 0;
                }
    
                .footer-sitemap-item {
                    cursor: pointer;
                    color: rgb(36, 36, 36);
                    text-decoration: underline;
                    font-family: $font-family;
                    font-size: 1.2rem;
                    font-weight: 400;
                    text-align: start;
                    margin: 0;
                }
            }
    
            .footer-iframe-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-size: 1.5rem;
                font-weight: 500;
                text-align: start;
                margin: 0;
                margin-top: 2rem;
                margin-bottom: 1rem;
                
            }
    
            .footer-iframe {
                border: none;
                height: 15rem;
                width: 100%;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }
        }

        .footer-bottom-container {
            position: absolute;
            display: flex;
            align-self: flex-end;
            align-items: center;
            justify-content: flex-start;
            width: 80%;
            bottom: 0;
            padding-left: 10%;
            padding-right: 10%;
            height: 5%;
            background-color: #91302b;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.9rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-name-img {
                object-fit: cover;
                height: 1rem;
                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: fit-content;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}