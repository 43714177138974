// ARCHIVO DE VARIABLES SASS, SOLO MODIFICAR ESTILOS DESDE ESTE ARCHIVO.

// VARIABLES GENERALES

$font-family: 'League Spartan', sans-serif;

$accent-font-family: 'Sanchez', serif;;

$main-color: white;

$accent-color: #F1382D;   

$accent-color-second: #6C8080;  
   
$dark-color: #373737; 

$background-color: #f6f6f6;

$btn-color: #F1382D;

$btn-color-hover: #b6322b;

$btn-color-active: rgb(133, 43, 38);

