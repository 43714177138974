@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
        min-height: 800px;

        .form-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 15%;
    
            .form-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .form-title {
                color: $accent-color;
                font-family: $font-family;
                font-weight: 700;
                font-size: 2.5rem;
                text-align: start;
                text-wrap: wrap;
                letter-spacing: -0.2rem;
                margin: 0;
                width: 100%;
    
                .form-title-color {
                    color: rgb(36, 36, 36);
    
                }
            }
        }
    
        .form-tag-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            height: 80%;
    
            .form-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 2rem;
    
                .form-input-label {
                    color: rgb(36, 36, 36);
                    font-size: 1rem;
                    font-family: $font-family;
                    font-weight: 500;
                    width: 100%;
                    text-align: start;
                    margin-bottom: 0.5rem;
                }
    
                .form-input {
                    color: white;
                    font-family: $font-family;
                    border: 1px solid $accent-color;
                    border-radius: 6px;
                    color: rgb(36, 36, 36);
                    height: 2.5rem;
                    border: 1px solid $accent-color;
                    background-color: #f8f8f8;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                }

                .error-message{
                    position: absolute;
                    bottom: -1.5rem;
                    margin: 0;
                    text-align: end;
                    width: 100%;
                    color: red;
                }
            }
    
            .form-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 1cap;
    
                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0px 0px 10px #313131cc;
        
                height: 3rem;
                width: 100%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .separator{
                display: flex;
                align-items: center;
                text-align: center;
                margin-bottom: 15px;
                width: 100%;
    
                .separator-line{
                    background-color: black;
                    width: 45%;
                    height: 10%;
                    margin-right: 1rem;
                }
                
                .separator-text{
                    margin-right: 0rem;
                }
            }
    
            .wpp-button{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 0rem;
                margin-bottom: 0rem;
                padding-top: 0.3%;
                text-decoration: none;
                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 3rem;
                width: 100%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }

                &:hover .wpp-icon {
                    color: $accent-color;
                    margin-right: 0.5rem;
                    font-size: 1.8rem;
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .wpp-icon{
                color: white;
                margin-right: 0.5rem;
                font-size: 1.8rem;
                transition: 0.3s;
            }
        }

        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
        
          .modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: black;
            box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow
            border-bottom: 1px solid $accent-color;
            width: 80%;
            height: 20rem;
            border-radius: 5px;
            text-align: center;
          }
        
          .modal-message {
            margin: 0;
            font-size: 1.2rem;
            color: white;
            font-size: 1.5rem;
            font-weight: 500;
            font-family: $font-family;
          }

          .modal-icon{
            color: $accent-color;
            font-size: 3rem;
            margin-top: 1.5rem;
            background-color: white;
            border-radius: 50%;
          }
        
          .modal-close-button {
            cursor: pointer;
            background-color: $accent-color;
            color: white;
            border: none;
            height: 3rem;
            width: 12rem;
            font-size: 1.5rem;
            font-family: $font-family;
            font-weight: 600;
            border-radius: 3px;
            margin-top: 2rem;
        
            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }
    }
}

@media (min-width: 769px) {
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;
        background-image: none;
    
        .form-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 15%;
    
            .form-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .form-title {
                color: $accent-color;
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                width: 100%;
    
                .form-title-color {
                    color: rgb(36, 36, 36);
    
                }
            }
        }
    
        .form-tag-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 80%;
            height: 70%;
    
            .form-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 70%;
                margin-bottom: 0.5rem;
    
                .form-input-label {
                    color: rgb(36, 36, 36);
                    font-size: 1.2rem;
                    font-family: $font-family;
                    font-weight: 500;
                    width: 100%;
                    text-align: start;
                    margin-bottom: 0.5rem;
                }
    
                .form-input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.2rem;
                    padding-left: 2%;
                    padding-top: 0.5%;
                    border: 1px solid $accent-color;
                    border-radius: 6px;
                    height: 3rem;
                    border: 1px solid $accent-color;
                    background-color: #f8f8f8;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                }

                .error-message{
                    position: absolute;
                    bottom: -1.5rem;
                    margin: 0;
                    text-align: end;
                    width: 100%;
                    color: red;
                }
            }
    
            .form-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 1rem;
                padding-top: 0.3%;
    
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: none;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 3.5rem;
                width: 70%;
                border: 1px solid $accent-color;

                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .separator{
                display: flex;
                align-items: center;
                text-align: center;
                margin-bottom: 20px;
                width: 100%;
    
                .separator-line{
                    background-color: black;
                    width: 32.5%;
                    height: 5%;
                    margin-right: 1rem;
                }
                
                .separator-text{
                    margin-right: 1rem;
                }
            }
    
            .wpp-button{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 0rem;
                margin-bottom: 0rem;
                padding-top: 0.3%;
                text-decoration: none;
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 3.5rem;
                width: 70%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }

                &:hover .wpp-icon {
                    color: $accent-color;
                    margin-right: 1rem;
                    font-size: 2.5rem;
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .wpp-icon{
                color: white;
                margin-right: 1rem;
                font-size: 2.5rem;
                transition: 0.3s;
            }
        }

        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
        
          .modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: black;
            box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow
            border-bottom: 1px solid $accent-color;
            padding: 3rem;
            border-radius: 5px;
            text-align: center;
          }
        
          .modal-message {
            margin: 0;
            font-size: 1.2rem;
            color: white;
            font-size: 1.5rem;
            font-weight: 500;
            font-family: $font-family;
          }

          
          .modal-icon{
            color: $accent-color;
            font-size: 3rem;
            margin-top: 1.5rem;
            background-color: white;
            border-radius: 50%;
          }
        
          .modal-close-button {
            cursor: pointer;
            background-color: $accent-color;
            color: white;
            border: none;
            height: 3rem;
            width: 12rem;
            font-size: 1.5rem;
            font-family: $font-family;
            font-weight: 600;
            border-radius: 3px;
            margin-top: 2rem;
        
            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }
    }
}

@media (min-width: 1200px) {
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;
        background-image: url('../../resources/images/form-bg.webp');
        background-size: cover;
    
        .form-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: 15%;
    
            .form-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
            }
    
            .form-title {
                color: $accent-color;
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                width: 100%;
    
                .form-title-color {
                    color: rgb(36, 36, 36);
    
                }
            }
        }
    
        .form-tag-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 80%;
            height: 70%;
    
            .form-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 60%;
    
                .form-input-label {
                    color: rgb(36, 36, 36);
                    font-size: 1.2rem;
                    font-family: $font-family;
                    font-weight: 500;
                    width: 100%;
                    text-align: start;
                    margin-bottom: 0.5rem;
                }
    
                .form-input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.2rem;
                    padding-left: 2%;
                    padding-top: 0.5%;
                    border: 1px solid $accent-color;
                    border-radius: 6px;
                    border: 1px solid $accent-color;
                    background-color: #f8f8f8;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                }

                .error-message{
                    position: absolute;
                    bottom: -1.5rem;
                    margin: 0;
                    text-align: end;
                    width: 100%;
                    color: red;
                }
            }
    
            .form-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 1rem;
                padding-top: 0.3%;
    
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0px 0px 10px #313131cc;
        
                height: 3.5rem;
                width: 60%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }

            }

            .separator{
                display: flex;
                align-items: center;
                text-align: center;
                margin-bottom: 20px;
                width: 100%;
    
                .separator-line{
                    background-color: black;
                    width: 28%;
                    height: 5%;
                    margin-right: 1rem;
                }
                
                .separator-text{
                    margin-right: 1rem;
                }
            }
    
            .wpp-button{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 0rem;
                margin-bottom: 0rem;
                padding-top: 0.3%;
                text-decoration: none;
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 3.5rem;
                width: 60%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }

                &:hover .wpp-icon {
                    color: $accent-color;
                    margin-right: 1rem;
                    font-size: 2.5rem;
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .wpp-icon{
                color: white;
                margin-right: 1rem;
                font-size: 2.5rem;
                transition: 0.3s;
            }
        }

        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
        
          .modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: black;
            box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow
            border-bottom: 1px solid $accent-color;
            padding: 3rem;
            border-radius: 5px;
            text-align: center;
          }
        
          .modal-message {
            margin: 0;
            font-size: 1.2rem;
            color: white;
            font-size: 1.5rem;
            font-weight: 500;
            font-family: $font-family;
          }

          
          .modal-icon{
            color: $accent-color;
            font-size: 3rem;
            margin-top: 1.5rem;
            background-color: white;
            border-radius: 50%;
          }
        
          .modal-close-button {
            cursor: pointer;
            background-color: $accent-color;
            color: white;
            border: none;
            height: 3rem;
            width: 12rem;
            font-size: 1.5rem;
            font-family: $font-family;
            font-weight: 600;
            border-radius: 3px;
            margin-top: 2rem;
        
            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }
    }
}

@media (min-width: 1600px) {
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        min-height: 800px;
        background-image: url('../../resources/images/form-bg.webp');
        background-size: cover;
    
        .form-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            height: fit-content;
            margin-bottom: 1rem;
    
            .form-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
                width: 100%;
                
            }
    
            .form-title {
                color: $accent-color;
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;
                width: 100%;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle letter shadow */

                .form-title-color {
                    color: rgb(36, 36, 36);
    
                }
            }
        }
    
        .form-tag-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 80%;
            height: 65%;
    
            .form-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 35%;
    
                .form-input-label {
                    color: rgb(36, 36, 36);
                    font-size: 1.2rem;
                    font-family: $font-family;
                    font-weight: 500;
                    width: 100%;
                    text-align: start;
                    margin-bottom: 0.5rem;
                }
    
                .form-input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(36, 36, 36);
                    font-family: $font-family;
                    font-size: 1.2rem;
                    padding-left: 2%;
                    padding-top: 0.5%;
                    border-radius: 6px;
                    height: 3rem;
                    border: 1px solid $accent-color;
                    background-color: #f8f8f8;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
                }

                .error-message{
                    position: absolute;
                    bottom: -1.5rem;
                    margin: 0;
                    text-align: end;
                    width: 100%;
                    color: red;
                }
            }
    
            .form-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 1rem;
                padding-top: 0.3%;
    
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 4rem;
                width: 35%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .separator{
                display: flex;
                align-items: center;
                text-align: center;
                margin-bottom: 20px;
                width: 80%;
    
                .separator-line{
                    background-color: black;
                    width: 20%;
                    height: 5%;
                    margin-right: 1rem;
                }
                
                .separator-text{
                    margin-right: 1rem;
                }
            }
    
            .wpp-button{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 0rem;
                margin-bottom: 0rem;
                padding-top: 0.3%;
                text-decoration: none;
                font-size: 1.8rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
        
                height: 4rem;
                width: 35%;
        
                transition: 0.3s;
        
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
        
                }

                &:hover .wpp-icon {
                    color: $accent-color;
                    margin-right: 1rem;
                    font-size: 2.5rem;
        
                }
        
                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }

            .wpp-icon{
                color: white;
                margin-right: 1rem;
                font-size: 2.5rem;
                transition: 0.3s;
            }
        }    

        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }
        
          .modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: black;
            box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow
            border-bottom: 1px solid $accent-color;
            padding: 3rem;
            border-radius: 5px;
            text-align: center;
          }
        
          .modal-message {
            margin: 0;
            font-size: 1.2rem;
            color: white;
            font-size: 1.5rem;
            font-weight: 500;
            font-family: $font-family;
          }

          
          .modal-icon{
            color: $accent-color;
            font-size: 3rem;
            margin-top: 1.5rem;
            background-color: white;
            border-radius: 50%;
          }
        
          .modal-close-button {
            cursor: pointer;
            background-color: $accent-color;
            color: white;
            border: none;
            height: 3rem;
            width: 12rem;
            font-size: 1.5rem;
            font-family: $font-family;
            font-weight: 600;
            border-radius: 3px;
            margin-top: 2rem;
        
            &:hover {
              background-color: darken($accent-color, 10%);
            }
          }
    }
}
