@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .nav-container {
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;
        width: 100%;
        opacity: 100%;
        color: white;
        background-color: white; // Start with a transparent background
        z-index: 99;
        transition: background-color 0.3s ease; // Add a smooth transition for the background color

        .nav-links-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 70%;
            height: 100%;

            .desktop-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;

                .desktop-menu-img {
                    object-fit: cover;
                    height: 70%;
                    margin-right: 0.5rem;
                }

                .desktop-menu-title {
                    font-family: $font-family;
                    font-weight: 400;
                }
            }

            .desktop-nav-links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 50%;

                .nav-single-link {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    font-size: 0.8rem;
                    font-family: $font-family;
                    font-weight: 400;
                    color: $main-color;
                    transition: 0.3s;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: $main-color;
                        transform-origin: bottom right;
                        transition: transform 0.25s ease-out;
                    }

                    &:hover::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                    }

                    &:focus {
                        cursor: pointer;
                        text-decoration: none;
                        color: white;
                    }
                }
            }

        }

        /* Adjust styles for mobile screens */
        justify-content: space-between;
        padding: 0px;
        background-color: transparent;

        .nav-links-container {
            display: none;
        }

        .mobile-menu {
            display: flex;
            align-items: center;
            height: 10vh;
            width: 100%;
            position: relative; // Add this line
        }

        .mobile-menu-button {
            pointer-events: none;
            display: block;
            width: fit-content;
            opacity: 0%;
            color: white;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            font-size: 1.5rem;
            cursor: pointer;
            margin-left: -2rem;
            transition: 0.3s;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }

        .mobile-menu-title-container {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 5%;

            .mobile-menu-title {
                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 400;
            }

            .mobile-menu-img {
                object-fit: cover;
                height: 100%;
                margin-left: -0.5rem;
            }
        }

        .mobile-dropdown-link {
            text-align: start;
            width: 65%;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-family: $font-family;
        }

        .mobile-dropdown {
            position: fixed;
            display: none;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            top: 10vh;
            left: -35%; // Change this to 100%
            background-color: rgba(0, 0, 0, 0.904);
            width: 100%;
            height: 100vh;
            padding: 20px;
            z-index: 101;
            transition: left 0.8s ease;
            overflow: hidden;
        }

        @keyframes slideIn {
            0% {
                left: -100%;
            }

            100% {
                left: -35%;
            }
        }


        .mobile-dropdown {
            left: -100%;
            animation: slideIn 0.8s ease forwards;
        }


        // Add the colored-nav class for the colored navbar
        &.colored-nav {
            background-color: rgb(248, 248, 248);
            opacity: 100%; // You may adjust the opacity if needed
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid $accent-color;

            .mobile-menu-button {
                pointer-events: all;
                display: block;
                width: fit-content;
                color: white;
                opacity: 100%;
                background-color: $btn-color;
                border: none;
                border-radius: 5px;
                font-size: 1.5rem;
                cursor: pointer;
                margin-left: 1rem;

                &:hover {
                    background-color: $btn-color-hover;
                }

                &:active {
                    background-color: $btn-color-active;
                }
            }

            .mobile-dropdown {
                position: fixed;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                top: 10vh;
                left: -35%; // Change this to 100%
                background-color: rgba(0, 0, 0, 0.904);
                width: 100%;
                height: 100vh;
                padding: 20px;
                z-index: 101;
                transition: left 0.8s ease;
                overflow: hidden;
            }

            .mobile-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 5%;

                .mobile-menu-title {
                    font-size: 1.5rem;
                    font-family: $font-family;
                    font-weight: 600;
                    color: $dark-color;
                }

                .mobile-menu-img {
                    object-fit: cover;
                    height: 100%;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .nav-container {
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;
        min-height: 60px;
        width: 100%;
        opacity: 100%;
        color: white;
        background-color: white; // Start with a transparent background
        z-index: 99;
        transition: background-color 0.3s ease; // Add a smooth transition for the background color

        .nav-links-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            padding-left: 10%;
            padding-right: 10%;
            height: 100%;

            .desktop-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;

                .desktop-menu-img {
                    cursor: pointer;
                    object-fit: cover;
                    height: 100%;
                    margin: 0rem;
                }

                .desktop-menu-title {
                    font-family: $font-family;
                    font-weight: 400;
                }
            }

            .desktop-nav-links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 70%;

                .nav-single-link {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    font-size: 0.8rem;
                    font-family: $font-family;
                    font-weight: 400;
                    color: $main-color;
                    transition: 0.3s;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: $accent-color;
                        transform-origin: bottom right;
                        transition: transform 0.25s ease-out;
                    }

                    &:hover::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                    }

                    &:focus {
                        cursor: pointer;
                        text-decoration: none;
                        color: white;
                    }
                }
            }

        }

        /* Adjust styles for mobile screens */
        justify-content: space-between;
        padding: 0px;
        background-color: transparent;


        .mobile-menu {
            display: none;
            align-items: center;
            height: 10vh;
            width: 100%;
        }

        .mobile-menu-button {
            display: block;
            color: white;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            font-size: 1.5rem;
            cursor: pointer;
            margin-left: 15px;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }

        .mobile-menu-title-container {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 5%;

            .mobile-menu-title {
                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 400;
            }

            .mobile-menu-img {
                object-fit: cover;
                height: 100%;
                margin-left: -0.5rem;
            }
        }

        .mobile-dropdown {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: rgba(0, 0, 0, 0.904);
            width: 100%;
            padding: 20px;
            z-index: 100;
            transition: 0.8s;
            max-height: 0;
            /* Start with a height of 0, so it's hidden initially */
            overflow: hidden;
            /* Hide any content that overflows the container */
            animation: slideDown 0.3s ease forwards;
            /* Apply the animation */
        }

        .mobile-dropdown-link {
            margin-bottom: 20px;
            font-size: 1rem;
            font-family: $font-family;
        }

        /* Define the keyframes for sliding down */
        @keyframes slideDown {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 500px;
                /* Adjust the final height to your preference */
            }
        }

        /* Define the keyframes for sliding up */
        @keyframes slideUp {
            0% {
                max-height: 500px;
                /* Set the final height from slideDown animation */
            }

            100% {
                max-height: 0;
            }
        }

        /* Add a class to trigger the dropdown animation */
        .opened {
            animation: slideDown 0.3s ease forwards;
        }

        /* Add a class to trigger the retract animation */
        .closed {
            animation: slideUp 0.3s ease forwards;
        }



        // Add the colored-nav class for the colored navbar
        &.colored-nav {
            background-color: rgb(248, 248, 248);
            opacity: 100%; // You may adjust the opacity if needed
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid $accent-color;

            .nav-links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 80%;
                padding-left: 10%;
                padding-right: 10%;
                height: 100%;

                .desktop-menu-title-container {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .desktop-menu-img {
                        object-fit: cover;
                        height: 100%;
                        margin: 0rem;
                    }

                    .desktop-menu-title {
                        font-family: $font-family;
                        font-weight: 400;
                    }
                }

                .desktop-nav-links-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    width: 70%;

                    .nav-single-link {
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        font-size: 0.8rem;
                        font-family: $font-family;
                        font-weight: 500;
                        color: rgb(36, 36, 36);
                        transition: 0.3s;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            transform: scaleX(0);
                            height: 2px;
                            bottom: 0;
                            left: 0;
                            background-color: $accent-color;
                            transform-origin: bottom right;
                            transition: transform 0.25s ease-out;
                        }

                        &:hover::after {
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }

                        &:focus {
                            cursor: pointer;
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

            }

            .mobile-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 5%;

                .mobile-menu-title {
                    font-size: 1.5rem;
                    font-family: $font-family;
                    font-weight: 600;
                    color: $dark-color;
                }

                .mobile-menu-img {
                    object-fit: cover;
                    height: 100%;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .nav-container {
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8vh;
        width: 100%;
        opacity: 100%;
        min-height: 60px;
        color: white;
        background-color: white; // Start with a transparent background
        z-index: 99;
        transition: background-color 0.3s ease; // Add a smooth transition for the background color

        .nav-links-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            padding-left: 10%;
            padding-right: 10%;
            height: 100%;

            .desktop-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;

                .desktop-menu-img {
                    cursor: pointer;
                    object-fit: cover;
                    height: 100%;
                    margin: 0rem;
                }

                .desktop-menu-title {
                    font-family: $font-family;
                    font-weight: 400;
                }
            }

            .desktop-nav-links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 50%;

                .nav-single-link {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    font-size: 1rem;
                    font-family: $font-family;
                    font-weight: 400;
                    color: $main-color;
                    transition: 0.3s;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: $accent-color;
                        transform-origin: bottom right;
                        transition: transform 0.25s ease-out;
                    }

                    &:hover::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                    }

                    &:focus {
                        cursor: pointer;
                        text-decoration: none;
                        color: white;
                    }
                }
            }

        }

        /* Adjust styles for mobile screens */
        justify-content: space-between;
        padding: 0px;
        background-color: transparent;


        .mobile-menu {
            display: none;
            align-items: center;
            height: 10vh;
            width: 100%;
        }

        .mobile-menu-button {
            display: block;
            color: white;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            font-size: 1.5rem;
            cursor: pointer;
            margin-left: 15px;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }

        .mobile-menu-title-container {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 5%;

            .mobile-menu-title {
                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 400;
            }

            .mobile-menu-img {
                object-fit: cover;
                height: 100%;
                margin-left: -0.5rem;
            }
        }

        .mobile-dropdown {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: rgba(0, 0, 0, 0.904);
            width: 100%;
            padding: 20px;
            z-index: 100;
            transition: 0.8s;
            max-height: 0;
            /* Start with a height of 0, so it's hidden initially */
            overflow: hidden;
            /* Hide any content that overflows the container */
            animation: slideDown 0.3s ease forwards;
            /* Apply the animation */
        }

        .mobile-dropdown-link {
            margin-bottom: 20px;
            font-size: 1rem;
            font-family: $font-family;
        }

        /* Define the keyframes for sliding down */
        @keyframes slideDown {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 500px;
                /* Adjust the final height to your preference */
            }
        }

        /* Define the keyframes for sliding up */
        @keyframes slideUp {
            0% {
                max-height: 500px;
                /* Set the final height from slideDown animation */
            }

            100% {
                max-height: 0;
            }
        }

        /* Add a class to trigger the dropdown animation */
        .opened {
            animation: slideDown 0.3s ease forwards;
        }

        /* Add a class to trigger the retract animation */
        .closed {
            animation: slideUp 0.3s ease forwards;
        }



        // Add the colored-nav class for the colored navbar
        &.colored-nav {
            background-color: rgb(248, 248, 248);
            opacity: 100%; // You may adjust the opacity if needed
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid $accent-color;

            .nav-links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 80%;
                padding-left: 10%;
                padding-right: 10%;
                height: 100%;

                .desktop-menu-title-container {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .desktop-menu-img {
                        object-fit: cover;
                        height: 100%;
                        margin: 0rem;
                    }

                    .desktop-menu-title {
                        font-family: $font-family;
                        font-weight: 400;
                    }
                }

                .desktop-nav-links-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    width: 50%;

                    .nav-single-link {
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        font-size: 1rem;
                        font-family: $font-family;
                        font-weight: 400;
                        color: rgb(36, 36, 36);
                        transition: 0.3s;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            transform: scaleX(0);
                            height: 2px;
                            bottom: 0;
                            left: 0;
                            background-color: $accent-color;
                            transform-origin: bottom right;
                            transition: transform 0.25s ease-out;
                        }

                        &:hover::after {
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }

                        &:focus {
                            cursor: pointer;
                            text-decoration: none;
                            color: white;
                        }
                    }
                }

            }

            .mobile-menu-title-container {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 5%;

                .mobile-menu-title {
                    font-size: 1.5rem;
                    font-family: $font-family;
                    font-weight: 600;
                    color: $dark-color;
                }

                .mobile-menu-img {
                    object-fit: cover;
                    height: 100%;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}