@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .about-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        padding-top: 4rem;
        padding-bottom: 4rem;
        min-height: 400px;
        box-shadow: 0px -53px 58px 17px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px -53px 58px 17px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px -53px 58px 17px rgba(0, 0, 0, 0.75);
        z-index: 97;

        .about-photos-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 18rem;
            width: 18rem;

            .about-photo-1 {
                position: absolute;
                object-fit: cover;
                width: 50%;
                height: auto;
                left: 0;
                top: -2rem;
                z-index: 97;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

            .about-photo-2 {
                position: absolute;
                object-fit: cover;
                width: 60%;
                height: auto;
                right: 0;
                bottom: 0;
                z-index: 96;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }
        }


        .about-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 80%;

            .about-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
            }

            .about-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 2.5rem;
                text-align: start;
                text-wrap: wrap;
                letter-spacing: -0.2rem;
                margin: 0;

                .about-title-color {
                    color: $accent-color;
                }
            }

            .about-textbox {
                text-align: start;
                font-family: $font-family;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 1.2rem;
                width: 100%;
            }

            .about-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 5rem;

                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: none;
                box-shadow: 0px 0px 10px #313131cc;
                border: 1px solid $accent-color;

                height: 3rem;
                width: 100%;

                transition: 0.3s;

                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);

                }

                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .about-container {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        height: 90vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
        min-height: 700px;
        box-shadow: none;
        z-index: 97;

        .about-photos-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 15rem;
            width: 15rem;

            .about-photo-1 {
                position: absolute;
                object-fit: cover;
                width: 50%;
                height: auto;
                left: 0;
                top: -2rem;
                z-index: 97;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

            .about-photo-2 {
                position: absolute;
                object-fit: cover;
                width: 60%;
                height: auto;
                right: 0;
                bottom: 0;
                z-index: 96;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

        }

        .about-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 60%;

            .about-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
            }

            .about-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 3rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;

                .about-title-color {
                    color: $accent-color;
                }
            }

            .about-textbox {
                text-align: start;
                font-family: $font-family;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 1.3rem;
                font-size: 1rem;
                width: 95%;
            }

            .about-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 5rem;

                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: none;
                box-shadow: 0px 0px 10px #313131cc;

                height: 3.5rem;
                width: 80%;
                border: 1px solid $accent-color;

                transition: 0.3s;

                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);

                }

                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .about-container {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        height: 90vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
        min-height: 700px;
        box-shadow: none;
        z-index: 97;

        .about-photos-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 30rem;
            width: 30rem;

            .about-photo-1 {
                position: absolute;
                object-fit: cover;
                width: 50%;
                height: auto;
                left: 0;
                top: -2rem;
                z-index: 97;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

            .about-photo-2 {
                position: absolute;
                object-fit: cover;
                width: 60%;
                height: auto;
                right: 0;
                bottom: 0;
                z-index: 96;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

        }


        .about-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 40%;

            .about-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
            }

            .about-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 4rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;

                .about-title-color {
                    color: $accent-color;
                }
            }

            .about-textbox {
                text-align: start;
                font-family: $font-family;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 1.3rem;
                font-size: 1.1rem;
                width: 95%;
            }

            .about-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 5rem;

                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: none;
                box-shadow: 0px 0px 10px #313131cc;

                height: 3.5rem;
                width: 80%;
                border: 1px solid $accent-color;

                transition: 0.3s;

                
                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);

                }

                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .about-container {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        background-image: none;
        background-size: cover;
        width: 70%;
        height: 90vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
        min-height: 700px;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        z-index: 97;

        .about-photos-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 30rem;
            width: 30rem;

            .about-photo-1 {
                position: absolute;
                object-fit: cover;
                width: 50%;
                height: auto;
                left: 0;
                top: -2rem;
                z-index: 97;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

            .about-photo-2 {
                position: absolute;
                object-fit: cover;
                width: 60%;
                height: auto;
                right: 0;
                bottom: 0;
                z-index: 96;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
            }

        }


        .about-text-container {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 50%;

            .about-sub-title {
                color: rgb(36, 36, 36);
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: start;
                margin: 0;
            }

            .about-title {
                color: rgb(36, 36, 36);
                font-family: $font-family;
                font-weight: 700;
                font-size: 5rem;
                text-align: start;
                text-wrap: nowrap;
                letter-spacing: -0.2rem;
                margin: 0;

                .about-title-color {
                    color: $accent-color;
                }
            }

            .about-textbox {
                text-align: start;
                font-family: $font-family;
                font-weight: 400;
                color: rgb(36, 36, 36);
                line-height: 1.5rem;
                font-size: 1.2rem;
                width: 95%;
            }

            .about-button {
                cursor: pointer;
                align-self: flex-start;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $accent-color;
                color: white;
                margin-top: 1rem;
                margin-bottom: 5rem;

                font-size: 1.5rem;
                font-family: $font-family;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid $accent-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */

                height: 4rem;
                width: 60%;

                transition: 0.3s;

                &:hover {
                    background-color: white;
                    color: $accent-color;
                    opacity: 90%;
                    border: 1px solid $accent-color;
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);

                }

                &:active {
                    background-color: $accent-color;
                    color: white;
                    opacity: 90%;
                }
            }
        }
    }
}