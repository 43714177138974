@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 15vh;
        min-height: 100px;
        border: 1px solid $accent-color;
        border-radius: 6px;
        background-color: #141414;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow

        .info-quote {
            display: none;
        }

        .info-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
            height: 100%;

            .info-text {
                text-align: start;
                font-family: $font-family;
                font-size: 0.9rem;
                font-weight: 400;
                color: $accent-color-second;
                width: 100%;
                margin: 0;
                margin-bottom: 0.3rem;
            }

            .info-text-a {
                text-align: start;
                color: $accent-color;
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.2rem;
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media (min-width: 769px) {
    .info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 15vh;
        min-height: 120px;
        border: 1px solid $accent-color;
        border-radius: 6px;
        background-color: #141414;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow

        .info-quote {
            display: flex;
            flex-direction: column;
            text-align: start;
            color: white;
            font-family: $font-family;
            font-weight: 300;
            font-size: 1.3rem;
            line-height: 1.5rem;
            width: 45%;
            margin: 0;

            .info-quote-color{
                color: $accent-color;
            }
        }

        .info-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 45%;
            height: 100%;

            .info-text {
                text-align: start;
                font-family: $font-family;
                font-size: 1em;
                font-weight: 300;
                color: $accent-color-second;
                width: 100%;
                margin: 0;
                margin-bottom: 0.3rem;
            }

            .info-text-a {
                text-align: start;
                color: $accent-color;
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.5rem;
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media (min-width: 1200px) {
    .info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 15vh;
        min-height: 120px;
        border: 1px solid $accent-color;
        border-radius: 6px;
        background-color: #141414;
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); // Red box shadow

        .info-quote {
            display: flex;
            flex-direction: column;
            text-align: start;
            color: white;
            font-family: $font-family;
            font-weight: 300;
            font-size: 1.8rem;
            width: 45%;
            margin: 0;

            .info-quote-color{
                color: $accent-color;
            }
        }

        .info-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 45%;
            height: 100%;

            .info-text {
                text-align: start;
                font-family: $font-family;
                font-size: 1em;
                font-weight: 300;
                color: $accent-color-second;
                width: 70%;
                margin: 0;
                margin-bottom: 0.3rem;
            }

            .info-text-a {
                text-align: start;
                color: $accent-color;
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.5rem;
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media (min-width: 1600px) {
    .info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 15vh;
        min-height: 120px;
        border: 1px solid $accent-color;
        border-radius: 6px;
        background-color: $accent-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); // Red box shadow

        .info-quote {
            display: flex;
            flex-direction: column;
            text-align: start;
            color: white;
            font-family: $font-family;
            font-weight: 600;
            font-size: 2rem;
            letter-spacing: -0.15rem;
            line-height: 2.5rem;
            width: 45%;
            margin: 0;

            .info-quote-color{
                color: white;
            }
        }

        .info-text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 45%;
            height: 100%;

            .info-text {
                text-align: start;
                font-family: $font-family;
                font-size: 1.1em;
                font-weight: 600;
                letter-spacing: -0.05rem;
                color: rgb(36, 36, 36);
                width: 70%;
                margin: 0;
                margin-bottom: 0.3rem;
            }

            .info-text-a {
                text-align: start;
                color: $accent-color;
                font-family: $accent-font-family;
                font-weight: 400;
                font-size: 1.8rem;
                width: 100%;
                margin: 0;
                color: rgb(36, 36, 36);
            }
        }
    }
}